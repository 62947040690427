/*
Theme Name: Kim 2012
Theme URI: http://wordpress.org/extend/themes/twentyeleven
Author: Ben Gummer
Author URI: http://wordpress.org/
Description: Kim's website theme for 2012
Version: 1.0
License: GNU General Public License
License URI: license.txt
Tags: dark, light, white, black, gray, one-column, two-columns, left-sidebar, right-sidebar, fixed-width, flexible-width, custom-background, custom-colors, custom-header, custom-menu, editor-style, featured-image-header, featured-images, full-width-template, microformats, post-formats, rtl-language-support, sticky-post, theme-options, translation-ready
*/

body {
	margin:0;
	padding:0;
	font:14px 'Helvetica Neue', 'HelveticaNeue', Helvetica, Arial, sans-serif;
	text-align:center;
	background:#081c3c url('/img/bg_1_ 081c3c.jpg') fixed no-repeat center top;
}

a {
	color:#0D4194;
	border:none;
}

.ngg-gallery-thumbnail a img { border:1px solid #FFF; -webkit-transition: border-color 0.1s linear; }
.ngg-gallery-thumbnail a:hover img { border-color:#999; background:#FFF; }

a img { border:none; }

.clear {
	clear:both;
}

#container {
	width:912px;
	margin:0 auto;
	text-align:left;
}

h1 {
	margin:20px 0 20px 6px;
	padding:0;
	background:url('/img/logo.png') no-repeat 0 0;
	height:99px;
	width:399px;
	overflow:hidden;

	behavior: url(/iepngfix/iepngfix.htc);
}

	h1 a {
		display:block;
		padding:0;
		margin:0;
		height:99px;
		width:399px;
		overflow:hidden;
		text-indent:-9999px;
	}

ul#menu {
	margin:0;
	padding:0 26px;
	background:url('/img/page_top.png') no-repeat 0 0;
	height:88px;
	overflow:hidden;
	list-style-type:none;

	behavior: url(/iepngfix/iepngfix.htc);
}

	ul#menu li {
		height:88px;
		float:left;
		width:215px;
	}

	ul#menu li a {
		display:block;
		height:88px;
		text-indent:-999px;
		-webkit-transition: opacity 0.1s linear;
	}

		ul#menu li#menu-welcome a { background:url('/img/menu-welcome.gif') no-repeat 50% 50%; }
		ul#menu li#menu-gallery a { background:url('/img/menu-gallery.gif') no-repeat 50% 50%; }
		ul#menu li#menu-about a   { background:url('/img/menu-about.gif')   no-repeat 50% 50%; }
		ul#menu li#menu-contact a { background:url('/img/menu-contact.gif') no-repeat 50% 50%; }

		ul#menu li a:hover { opacity:0.5; }

#content {
	background:url('/img/page_middle.png') repeat-y 0 0;
	padding:26px 26px 1px 26px;

/*	behavior: url('/iepngfix/iepngfix.htc');*/
}

#footer {
	background:url('/img/page_bottom.png') no-repeat center top;
	padding:40px 26px 12px 26px;
	height:28px;
	color:#FFFFFF;

	behavior: url(/iepngfix/iepngfix.htc);
}

.gallery-chooser {
	width:280px;
	float:left;

	list-style-type:none;
	margin:0 0 0 0;
	padding:0;

	position:relative;
	z-index:5;
}

.gallery-chooser li {
	float:left;
	margin:0;
	padding:0;
}

.gallery-chooser li a {
	display:block;
	width:50px;
	height:50px;
	margin:0 0 10px 10px;
	background:#FFFFFF url('/img/bounce.gif') no-repeat 50% 50%;
}

.gallery-chooser li a img {
	display:block;
	width:50px;
	height:50px;
}


.gallery-viewer {
	float:right;
	width:580px;
}

.gallery-viewer img.main-image {
	max-width:563px;
}



#gallery-fixer-one.fixed {
	position:fixed;
	top:10px;
	width:100%;
	left:0;
}

#gallery-fixer-one.fixed #gallery-fixer-two {
	width:912px;
	margin:0 auto;
	padding-right:36px;
}

h2 {
	margin:0;
}


.wpcf7-form input[type=text], .wpcf7-form textarea {
	width:400px;
	padding:4px;
	border:1px solid #999;
	font-size:12px;
}

	.wpcf7-form input[type=text]:hover, .wpcf7-form textarea:hover { border-color:#666; }
